import config from '@/utility/config';
import { getNewHomePageCMSProps } from '@/utility/contentful/utils';
import HomePage from './home/HomePage';

export async function getStaticProps() {
  const pageProps = await getNewHomePageCMSProps();

  return {
    props: pageProps,
    revalidate: config.contentfulRevalidateSec // In seconds
  };
}

export default HomePage;
